<template>
  <base-section id="discover-pub">
    <base-section-heading title="Pub Search">
      Use our comprehensive search tools to find exactly the right pub for you.
      Seach based on the critera that matter to you. We have well over 100 pubs
      to choose from and you are sure to find something to match your mood
    </base-section-heading>

    <base-avatar-card
      align="center"
      v-bind="card"
      class="text-center"
    />

    <v-divider class="my-8" />

    <div class="pa-8" />

    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Outdoor Seating</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.outdoorSeating"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.outdoorSeating == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Serving Food</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.food"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.food == item.value ? 'primary white--text' : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Live Music</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.liveMusic"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.liveMusic == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Cocktails</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.cocktails"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.cocktails == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Craft Beer</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.craftBeer"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.craftBeer == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Live Sports</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.liveSport"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.liveSport == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Hotel Bar</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.hotel"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.hotel == item.value ? 'primary white--text' : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Whiskey Bar</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.whiskeyBar"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.whiskeyBar == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Big Screen</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.bigScreen"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.bigScreen == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Age Limit</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.ageLimit"
                mandatory
              >
                <v-btn
                  v-for="item in ageLimits"
                  :key="item.value"
                  :value="item.value"
                  :class="[
                    filters.ageLimit == item.value ? 'primary white--text' : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Restuarant</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.restuarant"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.restuarant == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Late Bar</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.lateLicence"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.lateLicence == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Dj</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.dj"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.dj == item.value ? 'primary white--text' : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="3"
            >
              <span class="mr-3 text--subtitle-1">Nightclub</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.nightclub"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.nightclub == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col>
              <span class="mr-3 text--subtitle-1">Date Night</span>
            </v-col><v-col>
              <v-btn-toggle
                v-model="filters.dateNight"
                mandatory
              >
                <v-btn
                  v-for="item in options"
                  :key="item.value"
                  :class="[
                    filters.dateNight == item.value
                      ? 'primary white--text'
                      : '',
                  ]"
                >
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col>
              <base-btn
                :color="!theme.isDark ? 'accent' : 'white'"
                outlined
                :small="true"
                @click="search = false & clearFilters()"
              >
                CLEAR
              </base-btn>
            </v-col>
            <v-col>
              <base-btn
                :small="true"
                @click="
                  (selected = null) & (search = true) & filterPubs(filters)
                "
              >
                Filter
              </base-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
      </v-row>
      <v-divider class="my-8" />
      <transition name="fade">
        <v-row v-if="getFilteredPubs.length > 0">
          <v-col
            v-for="(pub, i) in getFilteredPubs"
            :key="i"
            cols="12"
            md="4"
          >
            <div @click="showDetail(pub)">
              <base-list-item
                :text="pub.name"
                :selected="selected == pub"
                :clickable="true"
              />
              <div v-if="selected == pub">
                <v-row>
                  <v-col>
                    <div
                      style="position: relative"
                      :style="mapCorrection(i)"
                    >
                      <pub-detail-map
                        :pub="pub"
                        :highlight="false"
                      />
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </transition>
      <transition name="fade">
        <v-row
          v-if="(getFilteredPubs.length === 0) & search"
          align="center"
        >
          <v-col>
            <div class="mr-3 text--subtitle-1 d-flex align-center">
              No pub matches your selections
            </div>
          </v-col>
        </v-row>
      </transition>
      <div class="pa-8" />
    </v-container>
  </base-section>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import PubDetailMap from '../../components/map/PubDetailMap.vue'

  export default {
    name: 'SectionDiscover',
    components: { PubDetailMap },
    inject: ['theme'],

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      selected: null,
      search: false,
      card: {
        title: 'Discover your next pub',
        icon: 'mdi-glass-mug-variant',
        outlined: true,
      },
      filters: {
        outdoorSeating: 2,
        food: 2,
        liveMusic: 2,
        craftBeer: 2,
        cocktails: 2,
        ageLimit: 2,
        liveSport: 2,
        tv: 2,
        hotel: 2,
        whiskeyBar: 2,
        restuarant: 2,
        lateLicence: 2,
        dj: 2,
        nightclub: 2,
        dateNight: 2,
        bigScreen: 2,
      },
      options: [
        { value: 0, name: 'Yes' },
        { value: 1, name: 'No' },
        { value: 2, name: "Don't Mind" },
      ],
      ageLimits: [
        { value: '18', name: '18' },
        { value: '21', name: '21' },
        { value: 2, name: "Don't Mind" },
      ],
    }),
    computed: {
      ...mapGetters(['getFilteredPubs']),
    },
    mounted () {
      this.filterPubs(null)
    },
    methods: {
      ...mapActions(['filterPubs']),
      showDetail (pub) {
        this.selected = this.selected ? null : pub
      },
      mapCorrection (index) {
        if (!this.$vuetify.breakpoint.mdAndDown) {
          const modulus = index % 3
          if (modulus === 1) {
            return 'left: -107%'
          } else if (modulus === 2) {
            return 'left: -214%'
          } else {
            return ''
          }
        }
        return ''
      },
      clearFilters () {
        this.filters = {
          outdoorSeating: 2,
          food: 2,
          liveMusic: 2,
          craftBeer: 2,
          cocktails: 2,
          ageLimit: 2,
          liveSport: 2,
          hotel: 2,
          whiskeyBar: 2,
          restuarant: 2,
          lateLicence: 2,
          dj: 2,
          nightclub: 2,
          dateNight: 2,
          bigScreen: 2,
        }
        this.filterPubs(null)
      },
    },
  }
</script>

<style scoped>
@import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";
.fade-enter-active {
  transition: opacity 2s ease;
}
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
